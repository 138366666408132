import axios from "axios";

const api = "routing"

const tablero = {
    getData: params => axios.get(`${api}/index`, { params }),
    porCedis: params => axios.get(`${api}/por-cedis`, { params }),
    getDataNoAsignado: params => axios.get(`${api}/index-no-asignado`, { params }),
    porCedisNoAsignado: params => axios.get(`${api}/por-cedis-no-asignado`, { params }),
    //consultarFiltro: () => axios.get(`${api}/filtro`),
    consultarFiltro: params => axios.get(`${api}/filtro`,{params}),
    dataAsignarOperador: params => axios.get(`${api}/data-asignar-operador`, { params }),

}

export default tablero
